@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.header {
    display: flex;
}

.value {
    margin-left: auto;
}

.value-change {
    color: @color-green-60;
    margin-left: 5px;
}

.info-icon {
    display: none;
    margin-left: 5px;
    @media @screen-gt-xs {
        display: inline-block;
    }
}

.info-link {
    @media @screen-gt-xs {
        display: none;
    }
}

.bottomsheet-content {
    text-align: center;
}

.bottomsheet-icon {
    height: 48px;
    background: url('images/icon.svg') no-repeat center;
}
