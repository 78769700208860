@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/spacing-base';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/tokens';
@import '../../../css/globals/blocks/vacancy-serp/vacancy-serp-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.vacancy-card-container {
    border-radius: @rounded-corner-default;
    padding: 4 * @spacing-base;
    justify-content: initial;
    position: relative;
    display: flex;
    transition: height 1s ease-in, padding-bottom 0.1s, padding-top 0.1s;

    @media @screen-gt-xs {
        padding: 6 * @spacing-base;
    }
}

.vacancy-card_clickme {
    border-top: 4px solid @magritte-color-background-warning-heavy;

    @media @screen-gt-xs {
        border-top: 0;
        border-left: 4px solid @magritte-color-background-warning-heavy;
    }
}

.hidden {
    display: none;
}

.separate-line {
    display: block;
}

.separate-line-on-xs {
    @media @screen-lt-s {
        display: block;
    }
}

.wide-container {
    @media @screen-lt-s {
        display: none;
    }
}

.narrow-container {
    @media @screen-gt-xs {
        display: none;
    }
}

.separate-line-on-s-magritte {
    display: block;

    .screen-gt-s({
        display: initial;
    });
}

.wide-container-magritte {
    display: none;

    .screen-gt-s({
        display: initial;
    });
}

.narrow-container-magritte {
    .screen-gt-s({
        display: none;
    });
}
