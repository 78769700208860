@import '~bloko/common/styles/colors';

.bar {
    height: 8px;
    border-radius: 10px;
    background: linear-gradient(to right, @color-red-60, @color-yellow-60, @color-green-60);
    overflow: hidden;
}

.bar_blue {
    .bar-progress {
        box-shadow: 10px 0 0 @color-blue-10;

        &::after {
            background: @color-blue-10;
        }
    }
}

.bar-progress {
    position: relative;
    width: 50%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 10px 0 0 @color-gray-20;

    &::after {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        width: 100vw;
        background: @color-gray-20;
    }
}

.bar-progress_animatable {
    transition: width 0.8s;
}
