@import '../../../../../style-mixins/shadow-block';
@import '../../../../../bloko/common/styles/colors';
@import '../../../../../bloko/common/styles/media/_screen';

.shadow-container {
    box-shadow: 0 6px 16px fade(@color-special-absolute-black, 25%);
    padding: 16px;

    @media @screen-gt-xs {
        padding: 20px;
    }
}
.shadow-container_redesigned {
    .shadow-block();

    padding: @shadow-block-padding;

    @media @screen-gt-xs {
        padding: @shadow-block-desktop-padding;
    }
}

.vacancy-actions {
    display: flex;
}

.vacancy-actions_applicant {
    flex-wrap: wrap;
}
